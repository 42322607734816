import { ServiceLinkList } from 'ui/components/3-organisms/ServiceLinkList/ServiceLinkList';
import { GLOBAL_THEME_DEFAULT } from '_constants';

// Contexts
import { useSiteContext } from 'application/contexts/SiteContext';

export const ServiceLinkListFeature: React.FC<Content.ServiceLinkList> = ({ content }) => {
	const { items } = content?.properties ?? {};
	const SiteContext = useSiteContext();
	const globalTheme: string = SiteContext.settings.globalTheme?.setTheme ?? GLOBAL_THEME_DEFAULT;

	return (
		<ServiceLinkList
			title={content?.properties.heading}
			items={items.map(({ content }) => {
				const { heading, kickerFirstWord, kickerSecondWord, link, text, separateLink } = content?.properties ?? {};

				return {
					heading,
					kicker: {
						firstWord: kickerFirstWord,
						secondWord: kickerSecondWord,
					},
					link,
					text,
					separateLink,
					globalTheme: globalTheme,
				};
			})}
			link={content?.properties?.link}
			kicker={{
				firstWord: content?.properties?.kickerFirstWord,
				secondWord: content?.properties?.kickerSecondWord,
			}}
			bgColor={content?.properties?.color}
		/>
	);
};
